//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import labeledControlLayout from "@/components/shared/labeled-control-layout";
import PinLoginKeyboard from "@/components/common/pin-login-keyboard";
import {mapActions} from "vuex";
import SecondaryButton from "@/components/shared/secondary-button";
import PrimaryButton from "@/components/shared/primary-button";
import {pinRules} from "@/utils/validationRules";
import AlertDialog from "@/components/common/alert-dialog";
import {pinMixin} from "@/mixins/pinMixin";
import {userCategoryCodes} from "@/enums/userCategoryCodes";

export default {
  name: "pin-login-card",
  mixins:[pinMixin],
  components: {
    AlertDialog,
    PinLoginKeyboard,
    labeledControlLayout,
    SecondaryButton,
    PrimaryButton
  },
  props: {
    logoUrl: {
      type: String,
      default: '',
    },
    loginText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      signInForm: {
        rememberMe: false,
        pinInput: '',
        showPassword:true,
      },
      loginContantInfo: {
        phone_number:'531-005-555',
        email_address:'fusion@gopiro.com'
      },
      pinKeyboard: false,
      unableToLoginDialog: false,
      pinRules,
      validPin: false,
    }
  },
  methods: {
    ...mapActions('userLoginsForPinLogin', ['updateSavedLogins','loginWithPinIfExists']),
    showKeyboardOnMobile() {
      if(window.innerWidth < 700) {
        this.pinKeyboard = true
      }
    },
    handlePinChange(newPin) {
      this.signInForm.pinInput = newPin;
    },
    async loginWithPin() {
      const loginInfo = await this.loginWithPinIfExists(this.signInForm.pinInput)
      try {
        const response = await this.$auth.loginWith('local',
          {
            data: {
              username: loginInfo.userName,
              password: loginInfo.password,
              categoryCode: userCategoryCodes.WEBPOS_USERS
          }}
        )

        if (response.status === 200 && response.data.token) {
          this.$toast.success('Logged in!')
          this.$emit('loggedIn')
        } else {
          this.unableToLoginDialog = true;
        }
      } catch (err) {
        this.unableToLoginDialog = true;
      }

    },


  },
}
