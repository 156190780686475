//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoginCard from "@/components/common/login-card";
import RegisterCard from "@/components/common/register-card";
import { mapActions, mapState } from "vuex";
import { adminCodes } from "~/enums/adminSettings";
import SelectLocationDialog from "@/components/common/select-location-dialog";
import PinLoginCard from "@/components/common/pin-login-card";

export default {
  components: {
    PinLoginCard,
    RegisterCard,
    LoginCard,
    SelectLocationDialog,
  },
  data() {
    return {
      visibleCard: "login",
      showSelectLocationDialog: false,
      logoUrl: '',
      loginText: '',
    }
  },
  head() {
    return {
      title: this.$t("login"),
    };
  },

  layout (context) {
    return 'non-logged-in'
  },

  computed: {
    ...mapState("common/companyLocations", ["companyLocations"]),
    IsLoggedIn() {
      return this.$auth.loggedIn;
    },
    ContactPhoneNumber() {
      return this.adminSettingByCode(adminCodes.ContactPhoneNumber).value;
    },
    ContactEmailAddress() {
      return this.adminSettingByCode(adminCodes.ContactEmailAddress).value;
    },
  },
  async mounted() {
    if (this.IsLoggedIn) {
    }
    this.getLogoForLogin()
    this.getTextForLogin()
  },
  methods: {
    ...mapActions("common/companyLocations", ["fetchCompanyLocations"]),
    ...mapActions("storeLocation", ["setLocation"]),
    async loggedIn() {
      // check user's company locations and show them on dialog
      await this.fetchCompanyLocations();
      if (this.companyLocations.length === 1){
        this.onLocationSelected(this.companyLocations[0])
      }
      else if (this.companyLocations.length > 1) {
        this.showSelectLocationDialog = true;
      }
    },
    onLocationSelected(location) {
      this.setLocation(location);
      this.$router.push("/");
    },
    async getLogoForLogin() {
      const logo = await this.$axios.$get('contents/webposlogocontent')
      this.logoUrl = logo.details.logo_img.fieldValue.fullFileUrl;
    },
    async getTextForLogin() {
      const text = await this.$axios.$get('contents/webposlogininfotextcontent')
      this.loginText = text.details.info_text.fieldValue.value;
    }
  },
};
